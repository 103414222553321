<template>
  <div>
    <a :href="youtube">
      <div class="photo-bg">
        <img class="icon-img" :alt="icon.alt" :src="icon.src" />
      </div>
    </a>
    <!-- <p>youTube</p> -->
  </div>
</template>

<script>
export default {
  props: ["icon", "youtube"],
};
</script>

<style lang="scss" scoped>
div {
  .photo-bg {
    text-align: center;
    .icon-img {
      margin: auto;
      width: 60%;
    }
    .title-img {
      margin: auto;
      width: 75%;
    }
  }
}
</style>
