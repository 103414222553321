<template>
  <div>
    <FooterNavYouTubeBtn
      v-for="(btn, index) in YouTubeBtn"
      :key="index"
      :icon="{
        alt: btn.icon.alt,
        src: require('@/assets/img/footer/' + btn.icon.src),
      }"
      :youtube="btn.youtube"
    />
    <ul>
      <li>
        <FooterNavLineBtn
          v-for="(btn, index) in LineBtn"
          :key="index"
          :icon="{
            alt: btn.icon.alt,
            src: require('@/assets/img/footer/' + btn.icon.src),
          }"
          :title="{
            alt: btn.title.alt,
            src: require('@/assets/img/footer/' + btn.title.src),
          }"
          :line="btn.line"
        />
      </li>
      <li>
        <FooterNavMailBtn
          v-for="(btn, index) in MailBtn"
          :key="index"
          :icon="{
            alt: btn.icon.alt,
            src: require('@/assets/img/footer/' + btn.icon.src),
          }"
          :title="btn.title"
          :mail="btn.mail"
        />
      </li>
      <li>
        <FooterNavTelBtn
          v-for="(btn, index) in TelBtn"
          :key="index"
          :icon="{
            alt: btn.icon.alt,
            src: require('@/assets/img/footer/' + btn.icon.src),
          }"
          :title="btn.title"
          :tel="btn.tel"
        />
      </li>
    </ul>
    <p>井上自動車販売株式会社</p>
  </div>
</template>

<script>
import FooterNavLineBtn from "./FooterNavLineBtn.vue";
import LineBtn from "../assets/json/footerNavLine.json";
import FooterNavMailBtn from "./FooterNavMailBtn.vue";
import MailBtn from "../assets/json/footerNavMail.json";
import FooterNavTelBtn from "./FooterNavTelBtn.vue";
import TelBtn from "../assets/json/footerNavTel.json";
import FooterNavYouTubeBtn from "./FooterNavYouBtn.vue";
import YouTubeBtn from "../assets/json/footerNavYouTube.json";

export default {
  components: {
    FooterNavLineBtn,
    FooterNavMailBtn,
    FooterNavTelBtn,
    FooterNavYouTubeBtn,
  },
  data() {
    return {
      LineBtn: LineBtn,
      MailBtn: MailBtn,
      TelBtn: TelBtn,
      YouTubeBtn: YouTubeBtn,
    };
  },
};
</script>

<style lang="scss" scoped>
div {
  background-color: #000033;
  ul {
    display: flex;
    flex: wrap;
    padding: 12px 24px 6px 24px;
    text-align: center;
    background-color: #000033;
    li {
      width: calc(100% / 3);
    }
  }
  p {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    padding: 3px 0px;
    margin: 0 104px;
    border-top: solid 1px #330033;
    // background-color: #000033;
  }
}
</style>
