<template>
  <div class="hamburger__container">
    <div class="main_logo">
      <router-link to="/"
        ><img
          alt="井上自動車販売 中古自動車 豊田市 愛知県"
          src="../assets/img/top/logo.png"
      /></router-link>
    </div>
    <div class="youtube">
      <a href="https://www.youtube.com/channel/UCo4sbd428B9ne4U_N-vgP8A">
        <img
          alt="井上自動車販売チャンネル YouTube 登録 発信"
          src="../assets/img/footer/inoue-channel.gif"
      /></a>
    </div>
    <!-- <div class="contact_us">
      <a class="mail-wrap" href="http://inouecd.jp/dist/form/contact.php">
        <img
          alt="井上自動車販売 中古自動車 豊田市 愛知県"
          src="../assets/img/top/mail-logo.gif"
        />
      </a>
    </div> -->
    <!--ハンバーガーメニューのボタン-->
    <div class="hamburger__btn" @click="activeBtn">
      <span
        class="line line_01"
        :class="{ btn_line01: isOpen, active: isOpen }"
      ></span>
      <span class="line line_02" :class="{ btn_line02: isOpen }"></span>
      <span
        class="line line_03"
        :class="{ btn_line03: isOpen, active: isOpen }"
      ></span>
    </div>
    <!--サイドメニュー-->
    <transition name="hamburger__menu">
      <div class="hamburger__menu" v-show="isOpen">
        <div class="container shadow mb-5">
          <div @click="closeBtn" class="bg-white shadow-lg mb-5">
            <router-link to="/"
              ><img
                class="pl-4 py-4 rounded-lg w-44"
                alt="井上自動車販売 中古自動車 豊田市 愛知県"
                src="../assets/img/top/logo.png"
            /></router-link>
          </div>
          <ul>
            <!-- <li>
              <router-link to="/"><span>Home</span></router-link>
            </li> -->
            <li>
              <a href="https://www.goo-net.com/pit/shop/0207788/top"
                ><strong>整備・修理の実績をみる</strong
                ><span>（グーネットピットへ）</span>
              </a>
            </li>
            <li>
              <a
                href="https://carbell.jp/partner/detail/?code=1000001024&sno=0"
              >
                <strong>新車販売を詳しくみる</strong><span>（新車市場へ）</span>
              </a>
            </li>
            <li>
              <a href="https://corecar-ra.jp/shop/detail?id=59">
                <strong>定額リースを詳しくみる</strong
                ><span>（コレCARラへ）</span>
              </a>
            </li>
            <li>
              <a href="https://ameblo.jp/inouecd/">
                <strong>ブログを詳しくみる</strong><span>（アメブロへ）</span>
              </a>
            </li>
            <li @click="closeBtn">
              <router-link to="/staff" replace>
                <strong>スタッフの1日</strong><span></span>
              </router-link>
            </li>
          </ul>
          <div class="bg-white py-6">
            <a
              class="grid grid-cols-5 mail-wrap"
              href="http://inouecd.jp/form/contact.php"
            >
              <img
                class="py-2 w-20 col-span-1"
                alt="井上自動車販売 中古自動車 豊田市 愛知県"
                src="../assets/img/top/mail-logo.gif"
              /><span class="col-span-4 mail-text"
                >お問い合わせはこちらから</span
              >
            </a>
          </div>
        </div>
      </div>
    </transition>
    <transition>
      <div class="mask" v-show="isCloseMask" @click="closeBtn"></div>
    </transition>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isOpen: false,
      isCloseMask: false,
    };
  },
  methods: {
    activeBtn: function () {
      this.isOpen = !this.isOpen;
      this.isCloseMask = !this.isCloseMask;
    },
    closeBtn: function () {
      this.isOpen = !this.isOpen;
      this.isCloseMask = !this.isCloseMask;
    },
  },
};
</script>

<style lang="scss" scoped>
.main_logo {
  position: fixed;
  top: 18px;
  left: 10px;
  width: 130px;
  // height: 72px;
  cursor: pointer;
  z-index: 5;
  // background-color: aqua;
  img {
    margin: 0;
  }
}
.contact_us {
  position: fixed;
  top: 16px;
  right: 20vw;
  width: 43px;
  // height: 72px;
  cursor: pointer;
  z-index: 5;
  // background-color: aqua;
}
.hamburger__btn {
  position: fixed;
  top: 10.5px;
  right: 2vw;
  width: 70px;
  height: 72px;
  cursor: pointer;
  z-index: 50;

  .line {
    position: absolute;
    top: 0;
    left: 20px;
    width: 30px;
    height: 2px;
    background: #333333;
    text-align: center;
    transition: all 0.5s;
    // background: rgba(255, 255, 255, 1);
  }

  .line_01 {
    top: 16px;
    // transition: 0.4s ease;
    transition-duration: 0.4s;
  }

  .line_02 {
    top: 26px;
    // transition: 0.4s ease;
    transition-duration: 0.4s;
  }

  .line_03 {
    top: 36px;
    // transition: 0.4s ease;
    transition-duration: 0.4s;
  }
  [class*="active"] {
    transition: 0.4s ease;
    background: #ffffff;
  }
}

.btn_line01 {
  transform: translateY(10px) rotate(-315deg);
  transition: 0.4s ease;
}
.btn_line02 {
  transition: 0.2s ease;
  opacity: 0;
}
.btn_line03 {
  transform: translateY(-10px) rotate(315deg);
  transition: 0.4s ease;
}

// サイドメニュー
.hamburger__menu-enter-active,
.hamburger__menu-leave-active {
  transition: opacity 0.4s, transform 0.4s;
}
.hamburger__menu-enter {
  opacity: 0;
  transform: translateX(-220px);
}
.hamburger__menu-leave-to {
  opacity: 0;
  transform: translateX(-220px);
}
.hamburger__menu-leave,
.hamburger__menu-enter-to {
  opacity: 1;
}

.hamburger__menu {
  background-color: #ffffff;
  z-index: 30;
  position: fixed;
  width: 255px;
  height: 80rem;
  top: 0;
  left: 0;

  .container {
    background-color: #18b0e7;
  }
  ul {
    background-color: #18b0e7;
    // padding: 32px 0;
    // padding-top: 8px;
    padding-bottom: 18px;
    text-align: start;
  }

  li {
    padding: 16px 0 16px 0;
    margin: 0 0 0 36px;
    list-style: none;
    line-height: 1;
  }

  a {
    text-decoration: none;
    // padding-bottom: 8px;
  }
  strong {
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    padding-bottom: 6px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: 0.4s ease;
  }
  strong:after {
    content: "";
    width: 6px;
    height: 6px;
    border: 0;
    border-top: solid 2px #ffffff;
    border-right: solid 2px #ffffff;
    position: absolute;
    top: 36%;
    right: -24px;
    margin-top: -4px;
    transform: rotate(45deg);
    transition: 0.4s ease;
  }
  strong:hover {
    color: rgb(66, 66, 66);
    transition: 0.4s ease;
    cursor: pointer;
  }

  strong:hover:after {
    content: "";
    width: 6px;
    height: 6px;
    border: 0;
    border-top: solid 2px rgb(66, 66, 66);
    border-right: solid 2px rgb(66, 66, 66);
    position: absolute;
    top: 36%;
    right: -30px;
    margin-top: -4px;
    transform: rotate(45deg);
    transition: 0.4s ease;
    cursor: pointer;
  }
  span {
    display: block;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 0px;
    margin-left: -6px;
    transition: 0.4s ease;
  }
  // span:hover {
  //   color: rgb(66, 66, 66);
  //   transition: 0.4s ease;
  //   cursor: pointer;
  // }
  .mail-wrap {
    margin: 0 30px;
    padding: 0;
    border-top: solid 0.13em rgb(226, 209, 209);
    border-bottom: solid 0.13em rgb(226, 209, 209);
  }
  .mail-text {
    color: #a1a0a0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
  }
  .mail-text:hover {
    color: #141414;
    transition: 0.4s ease;
  }
}

.mask {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.812);
  // opacity: 0.8;
  z-index: 1;
  cursor: pointer;
}

.youtube {
  display: none;
}

@media (min-width: 1024px) {
  .main_logo {
    position: fixed;
    top: 18px;
    left: 20px;
    width: 180px;
  }

  .youtube {
    display: block;
    position: fixed;
    top: 18px;
    right: 120px;
    width: 280px;
  }
}
</style>
