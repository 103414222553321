<template>
  <div class="blog-con">
    <Largetitle />
    <MiddleTitle />
    <div class="container4 lg:grid grid-cols-3">
      <div class="blog-wrap" v-for="(result, index) in results" :key="index">
        <div class="img-wrap">
          <img alt="ブログ写真" :src="result.thumbnail" />
        </div>
        <time
          ><i class="far fa-calendar-alt"></i
          >{{ replaceDate(result.timestamp[0]) }}</time
        >
        <h4>{{ result.title[0] }}</h4>
        <p>{{ replaceString(result.description) }}</p>
        <a :href="result.link[0]">この記事を詳しくみる</a>
      </div>
    </div>
    <Button />
  </div>
</template>

<script>
import dayjs from "dayjs";
import Largetitle from "@/components/LargeTitleBlog.vue";
import MiddleTitle from "@/components/MiddleTitleBlog.vue";
import Button from "@/components/ButtonToBlog.vue";

export default {
  components: {
    Largetitle,
    MiddleTitle,
    Button,
  },
  data: function () {
    return {
      results: null,
      show: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getBlogRss: function () {
      this.axios
        // .get("http://localhost/other/confirm/index.php")
        // .get("http://inouecd.jp/dist/confirm/index.php")
        .get("https://inouecd.jp/confirm/index.php")
        .then((response) => {
          // const results = JSON.parse(`"${response.data}"`);
          this.results = response.data;
          // console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    replaceDate: function (dates) {
      const newDate = dayjs(dates);
      const year = newDate.year();
      const month = newDate.month();
      const date = newDate.date();
      return `${year}/${month}/${date}`;
    },
    replaceString: function (strings) {
      const newString = strings.replace(/&nbsp;/g, "");
      return newString;
    },
    handleScroll: function () {
      const top = this.$el.getBoundingClientRect().top;
      this.show = top < window.innerHeight - 20;
    },
  },
  mounted: function () {
    this.getBlogRss();
  },
};
</script>

<style lang="scss" scoped>
.blog-con {
  .container4 {
    padding: 48px 0 18px 24px;
    background-color: #b2d5e2;
    .blog-wrap {
      padding: 36px 0 36px 36px;
      margin-bottom: 30px;
      background-color: #ffffff;
      border-radius: 30px 0 0 30px;
      text-align: start;
    }
    .img-wrap {
      padding-bottom: 12px;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
    time {
      font-size: 12px;
      font-weight: 600;
      font-family: "Roboto", sans-serif !important;
      letter-spacing: 0.08em;
      .far {
        padding-right: 6px;
      }
    }
    h4 {
      font-size: 14px;
      font-weight: 600;
      padding: 12px 0;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.08em;
      padding: 6px 24px 12px 0;
    }
    a {
      color: #1699c9;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.08em;
      position: relative;
      display: inline-block;
      text-decoration: none;
      transition: 0.4s ease;
    }
    a:after {
      content: "";
      width: 6px;
      height: 6px;
      border: 0;
      border-top: solid 2px #1699c9;
      border-right: solid 2px #1699c9;
      position: absolute;
      top: 34%;
      right: -24px;
      margin-top: -1px;
      transform: rotate(45deg);
      transition: 0.4s ease;
    }
    a:hover {
      color: #b2d5e2;
      transition: 0.4s ease;
      cursor: pointer;
      transition: 0.4s ease;
    }

    a:hover:after {
      content: "";
      width: 6px;
      height: 6px;
      border: 0;
      border-top: solid 2px #b2d5e2;
      border-right: solid 2px #b2d5e2;
      position: absolute;
      top: 34%;
      right: -30px;
      margin-top: -1px;
      transform: rotate(45deg);
      transition: 0.4s ease;
      cursor: pointer;
    }
  }
}
.animeTitle-enter-active {
  transition: opacity 2s, transform 2s;
}
.animeTitle-enter {
  opacity: 0;
  transform: translateX(-40px);
}
@media (min-width: 1024px) {
  .blog-con {
    margin-bottom: 6em;
    .container4 {
      margin: 0em 2.5rem 0 2.5rem;
      // padding: 48px 0 18px 24px;
      .blog-wrap {
        padding: 36px 0 54px 24px;
        margin: 0 24px 30px 0;
        border-radius: 30px 0px 0px 30px;
      }
      .img-wrap {
        padding-bottom: 12px;
        img {
          width: 100%;
          height: 130px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
