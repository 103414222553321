<template>
  <div class="header">
    <div class="inner-header flex">
      <h1>
        <TextAnime v-if="anime" />
      </h1>
    </div>
    <!--Waves Container-->
    <div>
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(255,255,255,0.7"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(255,255,255,0.5)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(255,255,255,0.3)"
          />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
    <!--Waves end-->
  </div>
</template>

<script>
import TextAnime from "@/components/TextAnime.vue";
// import TextAnime from "./TextAnime.vue";
export default {
  components: { TextAnime },
  name: "HelloWorld",
  data: function () {
    return {
      anime: true,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  // font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 3px;
  text-orientation: upright;
  text-align: start;
  position: absolute;
  top: -5px;
  // right: 0;
  // left: 0;
  // margin: auto;
  div {
    padding: 0 6px;
    font-size: 18px;
  }
}
p {
  // font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.header {
  position: relative;
  text-align: center;
  // background: url(https://picsum.photos/2000/1500);
  background-image: url(../assets/main-photo-sp-4.png);
  color: #333;
  // background-blend-mode: color-burn;
  background-size: cover;
  z-index: -1;

  // background: linear-gradient(#05fbff, #1e00ff);
}
.header::after {
  content: "";
  background: linear-gradient(rgba(156, 207, 212, 0.3), rgba(0, 0, 0, 0));
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 45vh;
  z-index: -1;
}
.inner-header {
  height: 75vh;
  width: 100%;
  margin: 0;
  padding: 0;
  writing-mode: vertical-rl;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  // height: 15vh;
  height: 25px;
  min-height: 20px;
  margin-bottom: -7px; /*Fix for safari gap*/
  // min-height: 100px;
  // max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (min-width: 768px) {
  h1 {
    top: 15px;

    div {
      padding: 0 6px;
      font-size: 22px;
    }
  }
  .header {
    position: relative;
    text-align: center;
    // background: url(https://picsum.photos/2000/1500);
    background-image: url(../assets/main-photo-sp-4.png);
    color: #333;
    background-blend-mode: color-burn;
    background-size: cover;
    // background-repeat: no-repeat;
    background-position: 50% 90%;
    z-index: -1;
  }
  .waves {
    height: 45px;
    min-height: 20px;
    margin-bottom: -7px; /*Fix for safari gap*/
    // min-height: 100px;
    // max-height: 150px;
  }
}
@media (min-width: 1024px) {
  h1 {
    top: 3%;
    // text-orientation: upright;
    letter-spacing: 5px;
    line-height: 2.5em;
    // text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
    //   0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
    //   0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
    //   0 0 3px #fff, 0 0 3px #fff;
    // position: relative;

    div {
      // position: relative;
      padding: 0 6px;
      font-size: 24px;
      // color: white;
    }
    // div::after {
    //   width: 100%;
    //   height: 100%;
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   mix-blend-mode: overlay;
    //   color: white;
    //   font-weight: bold;
    //   top: 20px;
    //   left: 30px;
    // }
  }
  .header {
    position: relative;
    text-align: center;
    // background: url(https://picsum.photos/2000/1500);
    background-image: url(../assets/main-photo-pc-2.png);
    color: #333;
    background-blend-mode: color-burn;
    background-size: cover;
    // background-repeat: no-repeat;
    background-position: 50% 70%;
    z-index: -1;
  }
  .inner-header {
    height: 75vh;
    width: 100%;
    margin: 0;
    padding: 0;
    writing-mode: horizontal-tb;
  }
  .flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // padding: 0 54px 0 0;
  }
  .waves {
    height: 45px;
    min-height: 20px;
    margin-bottom: -7px; /*Fix for safari gap*/
    // min-height: 100px;
    // max-height: 150px;
  }
}
</style>
