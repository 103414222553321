<template>
  <div class="container5">
    <LargeTitle />
    <MiddleTitle />
    <section class="lg:grid grid-cols-3 lg:mx-5 lg:mb-10">
      <MaintenanceService
        v-for="(content, index) in contents"
        :key="index"
        :title="content.title"
        :discription="content.discription"
        :carImg="{
          alt: content.carImg.alt,
          src: require('@/assets/img/contents/' + content.carImg.src),
        }"
        :photoImg="{
          alt: content.photoImg.alt,
          src: require('@/assets/img/contents/' + content.photoImg.src),
        }"
      >
      </MaintenanceService>
    </section>
  </div>
</template>

<script>
import MaintenanceService from "./MaintenanceService.vue";
import LargeTitle from "@/components/LargeTitleOurCompany.vue";
import MiddleTitle from "@/components/MiddleTitleOurCompany.vue";
import contents from "../assets/json/ourCompany.json";
export default {
  components: { MaintenanceService, LargeTitle, MiddleTitle },
  data: function () {
    return {
      contents: contents,
    };
  },
  // created: function () {
  //   contents.forEach((content) => {
  //     console.log(content);
  //   });
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container5 {
  margin: 60px 0 0 0;
}
</style>
