<template>
  <div>
    <LargeTitle />
    <MiddleTitle />
    <Contents />
    <!-- <Mail /> -->
    <Btn />
  </div>
</template>

<script>
import LargeTitle from "@/components/LargeTitleHybridMaster.vue";
import MiddleTitle from "@/components/MiddleTitleHybridMaster.vue";
import Contents from "@/components/ContentsHybridMaster.vue";
// import Mail from "@/components/Mail.vue";
import Btn from "@/components/ButtonToTop.vue";

export default {
  components: {
    LargeTitle,
    MiddleTitle,
    Contents,
    // Mail,
    Btn,
  },
};
</script>
