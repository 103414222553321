<template>
  <div class="home">
    <Jumbotron />
    <ReturnToTop />
    <div class="container">
      <LargeTitle />
      <MiddleTitle />
      <Contents />
      <CustomerService />
      <CarDealer />
      <BlogRss />
      <OurCompany />
    </div>
    <GoogleMap />
    <Footer />
    <div class="footer-nav">
      <FooterNav />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Jumbotron from "@/components/Jumbotron.vue";
import ReturnToTop from "@/components/ReturnToTop.vue";
import LargeTitle from "@/components/LargeTitle.vue";
import MiddleTitle from "@/components/MiddleTitle.vue";
import Contents from "@/components/Contents.vue";
import CustomerService from "@/components/ContentsCustomerService.vue";
import CarDealer from "@/components/ContentsCarDealer.vue";
import OurCompany from "@/components/ContentsOurCompany.vue";
import BlogRss from "@/components/BlogRss.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import Footer from "@/components/Footer.vue";
import FooterNav from "@/components/FooterNav.vue";
export default {
  name: "Home",
  components: {
    Jumbotron,
    ReturnToTop,
    LargeTitle,
    MiddleTitle,
    Contents,
    CustomerService,
    CarDealer,
    BlogRss,
    OurCompany,
    GoogleMap,
    Footer,
    FooterNav,
  },
};
</script>

<style style scoped lang="scss">
.home {
  .footer-nav {
    position: fixed; /* 固定 */
    bottom: 0; /* 一番下に表示 */
    width: 100%; /* 中央に表示 */
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1200px;
    bottom: 0;
    // background-color: #b2d5e2;
  }
  .footer-nav {
    display: none;
  }
}
</style>
