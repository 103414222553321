var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('section',{staticClass:"lg:grid grid-cols-3 lg:mx-5 lg:mb-10"},_vm._l((_vm.contents),function(content,index){return _c('HybridMaster',{key:index,attrs:{"title":content.title,"discription":content.discription,"carImg":{
        alt: content.carImg.alt,
        src: require('@/assets/img/contents/' + content.carImg.src),
      },"photoImg":{
        alt: content.photoImg.alt,
        src: require('@/assets/img/contents/' + content.photoImg.src),
      },"photoImg2":{
        alt: content.photoImg.alt,
        src: require('@/assets/img/contents/' + content.photoImg2.src),
      }}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }