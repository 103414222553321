<template>
  <div>
    <a class="mail-wrap" :href="mail">
      <div class="photo-bg">
        <img class="icon-img" :alt="icon.alt" :src="icon.src" />
      </div>
      <div class="photo-bg">
        <span class="title">{{ title }}</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "mail"],
};
</script>

<style lang="scss" scoped>
div {
  .photo-bg {
    text-align: center;
    .icon-img {
      margin: auto;
      // padding-bottom: 1px;
      width: 22%;
      color: #fff;
    }
    .title {
      color: #fff;
      font-size: 13px;
      font-weight: 700;
    }
  }
}
</style>
