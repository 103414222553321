<template>
  <div class="container">
    <section class="lg:grid grid-cols-3 lg:mx-5 lg:mb-10">
      <HybridMaster
        v-for="(content, index) in contents"
        :key="index"
        :title="content.title"
        :discription="content.discription"
        :carImg="{
          alt: content.carImg.alt,
          src: require('@/assets/img/contents/' + content.carImg.src),
        }"
        :photoImg="{
          alt: content.photoImg.alt,
          src: require('@/assets/img/contents/' + content.photoImg.src),
        }"
        :photoImg2="{
          alt: content.photoImg.alt,
          src: require('@/assets/img/contents/' + content.photoImg2.src),
        }"
      />
    </section>
  </div>
</template>

<script>
import HybridMaster from "./HybridMaster.vue";
import contents from "../assets/json/hybridMaster.json";
export default {
  components: { HybridMaster },
  data: function () {
    return {
      contents: contents,
    };
  },
  created() {
    this.moveToTop();
  },
  methods: {
    moveToTop() {
      const duration = 500; // 移動速度（1秒で終了）
      const interval = 25; // 0.025秒ごとに移動
      const step = -window.scrollY / Math.ceil(duration / interval); // 1回に移動する距離
      const timer = setInterval(() => {
        window.scrollBy(0, step); // スクロール位置を移動

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .container {
// position: relative;
// padding-bottom: 1px;
// }
// .container::before {
//   display: block;
//   position: absolute;
//   width: 100%;
//   height: 572vw;
//   content: "";
//   background-image: url(../assets/img/top/back-bace.png);
//   background-size: contain;
//   background-repeat: repeat;
//   z-index: -2;
//   opacity: 0.7;
// }
@media (min-width: 768px) {
  .content {
    position: relative;
  }
  .container::before {
    // display: block;
    // position: absolute;
    width: 1000px;
    height: 100%;
    // content: "";
    background-image: none;
    // background-size: cover;
    // background-repeat: repeat;
    // z-index: -2;
    // opacity: 0.7;
  }
}
</style>
