<template>
  <div class="btn">
    <transition name="animeButton">
      <div v-show="buttonShow" class="button-wrap lg:w-1/2 lg:mx-auto">
        <a href="https://ameblo.jp/inouecd/">
          <h3 class="">
            <strong>ブログを詳しくみる</strong>
            <hr />
            <span>（アメブロへ）</span>
          </h3></a
        >
      </div></transition
    >
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      buttonShow: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      const top = this.$el.getBoundingClientRect().top;
      if (!this.buttonShow) {
        this.buttonShow = top < window.innerHeight - 20;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background-color: #b2d5e2;
  .button-wrap {
    padding: 0 48px 48px 48px;
    margin-bottom: 48px;
    // background-color: #b2d5e2;
    h3 {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding: 6px 0;
      background-color: #18b0e7;
      transition: 0.4s ease;
      // letter-spacing: 4px;
      // margin: 30px 0;
      strong {
        color: #ffffff;
        font-size: 14px;
        text-decoration: none;
        padding-bottom: 6px;
        position: relative;
        display: inline-block;
        text-decoration: none;
      }
      strong:after {
        content: "";
        width: 6px;
        height: 6px;
        border: 0;
        border-top: solid 2px #ffffff;
        border-right: solid 2px #ffffff;
        position: absolute;
        top: 36%;
        right: -24px;
        margin-top: -4px;
        transform: rotate(45deg);
      }
      hr {
        margin-left: 36px;
        border-top: solid 1px #ffffff;
        overflow: visible;
        position: relative;
        // z-index: -1;
      }
      hr:after {
        position: absolute;
        top: -0.23em;
        left: 0%;
        content: "";
        width: 0.4em;
        height: 0.4em;
        border-radius: 50%;
        background-color: #ffffff;
        transform: translate(-50%, 0);
        // z-index: -1;
      }
      span {
        display: block;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        padding: 6px 0px;
        margin-left: -6px;
      }
    }
    h3:hover {
      opacity: 0.5;
      transition: 0.4s ease;
      cursor: pointer;
    }
  }
}
@media (min-width: 1024px) {
  .btn {
    margin: 0 2.5rem 0 2.5rem;
    .button-wrap {
      padding: 0 48px 60px 48px;
    }
  }
}

.animeButton-enter-active {
  animation: button-in 1.2s;
}
// .animeButton-enter {
//   opacity: 0;
//   transform: translateX(-40px);
// }
@keyframes button-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
