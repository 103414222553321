<template>
  <div class="container3">
    <MiddleTitle />
    <section class="lg:grid grid-cols-3 lg:mx-5 lg:mb-10">
      <MaintenanceService
        v-for="(content, index) in contents"
        :key="index"
        :title="content.title"
        :discription="content.discription"
        :carImg="{
          alt: content.carImg.alt,
          src: require('@/assets/img/contents/' + content.carImg.src),
        }"
        :photoImg="{
          alt: content.photoImg.alt,
          src: require('@/assets/img/contents/' + content.photoImg.src),
        }"
      >
      </MaintenanceService>
    </section>
    <div class="button">
      <ButtonN />
      <ButtonC />
    </div>
  </div>
</template>

<script>
import MaintenanceService from "./MaintenanceService.vue";
import MiddleTitle from "@/components/MiddleTitleCarDealer.vue";
import ButtonN from "@/components/ButtonToNewCar.vue";
import ButtonC from "@/components/ButtonToCoreCarRa.vue";
import contents from "../assets/json/carDealer.json";
export default {
  components: { MaintenanceService, MiddleTitle, ButtonN, ButtonC },
  data: function () {
    return {
      contents: contents,
    };
  },
  // created: function () {
  //   contents.forEach((content) => {
  //     console.log(content);
  //   });
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container3 {
  margin: 90px 0 0 0;
  // section {
  //   position: relative;
  //   padding-bottom: 1px;
  //   // background-image: url(../assets/img/top/back-bace.png);
  //   // z-index: -2;
  // }
  // section::before {
  //   display: block;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   content: "";
  //   background-image: url(../assets/img/top/back-bace.png);
  //   // background-size: cover;
  //   // background-repeat: repeat;
  //   z-index: -2;
  //   opacity: 0.7;
  // }
  // .button {
  //   padding-bottom: 36px;
  // }
  // .button::before {
  //   display: block;
  //   position: absolute;
  //   width: 100%;
  //   height: 40%;
  //   content: "";
  //   background-image: url(../assets/img/top/back-bace.png);
  //   // background-size: cover;
  //   // background-repeat: repeat;
  //   z-index: -2;
  //   opacity: 0.7;
  // }
}

@media (min-width: 1024px) {
  .container3 {
    margin: 60px 0 84px 0;
  }
}
</style>
