<template>
  <div>
    <transition name="fade">
      <div id="pagetop" class="shape" v-show="scY > 300" @click="toTop">
        Top
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#4a5568"
          stroke-width="1"
          stroke-linecap="square"
          stroke-linejoin="arcs"
        >
          <path d="M18 15l-6-6-6 6" />
        </svg> -->
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
#pagetop {
  z-index: 5;
  position: fixed;
  right: 5px;
  bottom: 20px;
  height: 50px;
  text-decoration: none;
  font-weight: bold;
  transform: rotate(90deg);
  font-size: 90%;
  line-height: 1.5rem;
  color: #737373;
  padding: 0 0 0 35px;
  border-top: solid 1px;
  cursor: pointer;
}
#pagetop::before {
  // z-index: 6;
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: 0px;
  width: 15px;
  border-top: solid 1px;
  transform: rotate(35deg);
  transform-origin: left top;
}
@media (min-width: 1024px) {
  #pagetop {
    right: 88px;
    bottom: 20px;
    height: 50px;
  }
}
</style>
