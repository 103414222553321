<template>
  <div class="footer">
    <div class="inner-footer flex">
      <h1>
        <transition name="animeTitle">
          <div v-show="titleShow" class="text">
            <span>これまでも、これからも、</span>
          </div>
        </transition>
        <transition name="animeTitle">
          <div v-show="titleShow2" class="text">
            <span>車好きの明るいスタッフが</span>
          </div>
        </transition>
        <transition name="animeTitle">
          <div v-show="titleShow3" class="text">
            <span>丁寧に仕事に励みます。</span>
          </div>
        </transition>
      </h1>
      <transition name="animeTitle">
        <div v-show="logoShow" class="logo"></div>
      </transition>
      <h2>
        <transition name="animeTitle">
          <div v-show="companyShow" class="text">
            <span>井上自動車販売株式会社 </span>
          </div>
        </transition>
        <transition name="animeTitle">
          <div v-show="companyShow2" class="text">
            <span>〒470-0375&nbsp;愛知県豊田市亀首町山川35-12</span>
          </div>
        </transition>
        <transition name="animeTitle">
          <div v-show="companyShow3" class="text">
            <span>TEL : 0565-45-7302</span>
          </div>
        </transition>
        <transition name="animeTitle">
          <div v-show="companyShow4" class="text">
            <span>営業時間 : 10:00 - 18:00</span>
          </div>
        </transition>
      </h2>
      <transition name="animeTitle">
        <a
          v-show="contactShow"
          class="mail-wrap"
          href="https://inouecd.jp/form/contact.php"
        >
          <div class="inner-wrap py-6">
            <img
              class="py-2 w-14 lg:py-2 lg:w-12 footer-log"
              alt="井上自動車販売 中古自動車 豊田市 愛知県"
              src="../assets/img/top/mail-logo.gif"
            />
            <span class="mail-text">車のご相談はこちらから</span>
          </div></a
        >
      </transition>
      <transition name="animeTitle">
        <small v-show="smallShow"
          ><span>&copy;inouezidousya-hanbai</span></small
        >
      </transition>
    </div>
    <!--Waves Container-->
    <div></div>
    <!--Waves end-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleShow: false,
      titleShow2: false,
      titleShow3: false,
      logoShow: false,
      companyShow: false,
      companyShow2: false,
      companyShow3: false,
      companyShow4: false,
      contactShow: false,
      smallShow: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      const top = this.$el.getBoundingClientRect().top;
      if (!this.titleShow) {
        this.titleShow = top < window.innerHeight - 20;
      }
      if (!this.titleShow2) {
        this.titleShow2 = top < window.innerHeight - 30;
      }
      if (!this.titleShow3) {
        this.titleShow3 = top < window.innerHeight - 40;
      }
      if (!this.logoShow) {
        this.logoShow = top < window.innerHeight - 100;
      }
      if (!this.companyShow) {
        this.companyShow = top < window.innerHeight - 220;
      }
      if (!this.companyShow2) {
        this.companyShow2 = top < window.innerHeight - 230;
      }
      if (!this.companyShow3) {
        this.companyShow3 = top < window.innerHeight - 240;
      }
      if (!this.companyShow4) {
        this.companyShow4 = top < window.innerHeight - 250;
      }
      if (!this.contactShow) {
        this.contactShow = top < window.innerHeight - 350;
      }
      if (!this.smallShow) {
        this.smallShow = top < window.innerHeight - 500;
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  margin-bottom: 70px;
  position: relative;
  text-align: center;
  // background: url(https://picsum.photos/2000/1500);
  background-image: url(../assets/img/top/footer-photo.jpg);
  color: #333;
  background-blend-mode: color-burn;
  background-size: cover;
  // z-index: -1;

  .inner-footer {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
      // font-family: "Lato", sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      // text-align: start;
      position: absolute;
      top: 60px;
      .text {
        color: #ffffff;
        padding: 0 6px;
        font-size: 18px;
        span {
          font-size: 15px;
        }
      }
    }
    .logo {
      width: 35%;
      height: 50px;
      background-image: url(../assets/img/top/logo-w.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 210px;
    }
    h2 {
      // font-family: "Lato", sans-serif;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1.3em;
      position: absolute;
      top: 260px;
      .text {
        color: #ffffff;
        padding: 0 6px;
        font-size: 18px;
        span {
          font-size: 14px;
        }
      }
    }
    .mail-wrap {
      // position: absolute;
      // top: 280px;
      // z-index: -1;
      .inner-wrap {
        width: 300px;
        // margin: 0 66px;
        padding: 0;
        border-top: solid 0.13em rgb(255, 255, 255);
        border-bottom: solid 0.13em rgb(255, 255, 255);
        display: flex;
        .footer-log {
          height: 70px;
          object-fit: cover;
        }
        .mail-text {
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          padding-left: 15px;
        }
      }
    }
    small {
      position: absolute;
      top: 630px;
      span {
        color: #ffffff;
        font-weight: 600;
      }
    }
  }
  a {
    display: block;
    position: relative;
    top: 100px;
    cursor: pointer;
  }
  a:hover {
    opacity: 0.5;
    color: #141414;
    transition: 0.4s ease;
  }

  .content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
  }
}
.footer::before {
  background-color: rgba(0, 0, 0, 0.1);
  /* どの範囲に重ねるかを指定 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
.animeTitle-enter-active {
  transition: opacity 2s, transform 2s;
}
.animeTitle-enter {
  opacity: 0;
  transform: translateX(-40px);
}

@media (max-width: 360px) {
  .footer {
    .flex {
      h1 {
        top: 60px;
        .text {
          span {
            font-size: 14px;
          }
        }
      }
      .logo {
        top: 190px;
      }
      h2 {
        top: 250px;
        .text {
          span {
            font-size: 13px;
          }
        }
      }
      .mail-wrap {
        top: 120px;
        .inner-wrap {
          width: 250px;
          // margin: 0 66px;
          padding: 0;
          border-top: solid 0.13em rgb(255, 255, 255);
          border-bottom: solid 0.13em rgb(255, 255, 255);
          display: flex;
          .footer-log {
            width: 50px;
            height: 68px;
            object-fit: cover;
          }
          .mail-text {
            padding-left: 15px;
          }
        }
      }
      small {
        position: absolute;
        top: 530px;
        span {
          color: #ffffff;
          font-weight: 600;
        }
      }
    }
    a {
      display: block;
      position: relative;
      top: 100px;
      cursor: pointer;
    }
    a:hover {
      opacity: 0.5;
      color: #141414;
      transition: 0.4s ease;
    }

    .content {
      position: relative;
      height: 20vh;
      text-align: center;
      background-color: white;
    }
  }
  .footer::before {
    background-color: rgba(0, 0, 0, 0.1);
    /* どの範囲に重ねるかを指定 */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
  }
  .animeTitle-enter-active {
    transition: opacity 2s, transform 2s;
  }
  .animeTitle-enter {
    opacity: 0;
    transform: translateX(-40px);
  }
}

@media (min-width: 1024px) {
  .footer {
    background-image: url(../assets/img/top/footer-photo-pc.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 85%;
    margin-bottom: 0px;
    .inner-footer {
      height: 70vh;
      width: 100%;
    }
    .flex {
      h1 {
        font-weight: 600;
        letter-spacing: 1px;
        // text-align: start;
        position: absolute;
        top: 66px;
        .text {
          color: #ffffff;
          padding: 0 6px;
          font-size: 22px;
          span {
            font-size: 15px;
          }
        }
      }
      .logo {
        width: 12%;
        height: 50px;
        top: 210px;
      }
      h2 {
        // font-family: "Lato", sans-serif;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1.4em;
        position: absolute;
        top: 270px;
        .text {
          color: #ffffff;
          padding: 0 6px;
          font-size: 18px;
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .mail-wrap {
        .inner-wrap {
          .footer-log {
            height: auto;
          }
          .mail-text {
            font-size: 16px;
            padding-left: 30px;
          }
        }
      }
      small {
        top: 510px;
      }
    }
    a {
      display: block;
      position: relative;
      top: 150px;
      cursor: pointer;
    }
  }
}
</style>
