<template>
  <div>
    <transition name="animeTitle">
      <h4 v-show="show">アクセスはこちら。</h4>
    </transition>
    <div class="frame-wrap">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13051.831941542212!2d137.171153!3d35.132722!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe3e8bd794f0d4a1e!2z5LqV5LiK6Ieq5YuV6LuK6LKp5aOy!5e0!3m2!1sja!2sjp!4v1623399606496!5m2!1sja!2sjp"
        width="600"
        height="450"
        style="border: 0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        aria-hidden="false"
        tabindex="0"
        loading="lazy"
        class="map"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      var top = this.$el.getBoundingClientRect().top;
      this.show = top < window.innerHeight - 30;
    },
  },
};
</script>

<style scoped lang="scss">
div {
  // margin-bottom: 6px;
  h4 {
    display: block;
    // display: inline-block;ï
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-align: start;
    margin: 12px 0 24px 0;
    padding-left: 48px;
    position: relative;
    z-index: -1;
    // background-color: #b2d5e2;
    // background-position: left top;
  }
  h4::after {
    display: block;
    content: "";
    position: absolute;
    top: -10px;
    left: 30px;
    height: 100%;
    width: 180px;
    background-color: #b2d5e2;
    z-index: -2;
  }
  .frame-wrap {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; /* 縦横比の指定 */
    overflow: hidden;
    margin: 0;
    .map {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
// .animeTitle {
//   animation: text-in1 0.8s cubic-bezier(0.22, 0.15, 0.25, 1.43) 2s backwards;
// }
// @keyframes text-in2 {
//   0% {
//     transform: translateX(-40px);
//     opacity: 0;
//   }
//   100% {
//     transition: opacity 2s, transform 2s;
//   }
// }
.animeTitle-enter-active {
  transition: opacity 2s, transform 2s;
}
.animeTitle-enter {
  opacity: 0;
  transform: translateX(-40px);
}
@media (min-width: 1024px) {
  div {
    margin-top: 7em;
    margin-bottom: 0px;
    h4 {
      display: none;
    }
    .frame-wrap {
      border: 0;
      padding-bottom: 450px;
      .map {
        width: 100%;
        height: 450px;
        border: 0;
      }
    }
  }
}
</style>
