<template>
  <div>
    <a :href="line">
      <div class="photo-bg">
        <img class="icon-img" :alt="icon.alt" :src="icon.src" />
      </div>
      <div class="photo-bg">
        <img class="title-img" :alt="title.alt" :src="title.src" /></div
    ></a>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "line"],
};
</script>

<style lang="scss" scoped>
div {
  .photo-bg {
    text-align: center;
    .icon-img {
      margin: auto;
      padding-bottom: 10px;
      width: 20%;
    }
    .title-img {
      margin: auto;
      width: 75%;
    }
  }
}
</style>
