<template>
  <div class="my-5">
    <transition name="animeTitle">
      <h4>{{ title }}</h4>
    </transition>
    <p class="css-test">
      {{ discription }}
    </p>
    <div class="img-wrap">
      <transition name="animeTitle2">
        <img class="car-img" :alt="carImg.alt" :src="carImg.src" />
      </transition>
      <div class="photo-bg">
        <transition name="animeTitle2">
          <img class="photo-img" :alt="photoImg.alt" :src="photoImg.src" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "discription", "carImg", "photoImg"],
  data() {
    return {
      titleShow: false,
      carShow: false,
      photoShow: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  // xxx() {
  //   return require(this.carImg.src);
  // },
  methods: {
    handleScroll: function () {
      const top = this.$el.getBoundingClientRect().top;
      if (!this.titleShow) {
        this.titleShow = top <= window.innerHeight + 10;
      }
      if (!this.carShow) {
        this.carShow = top < window.innerHeight + 10;
      }
      if (!this.photoShow) {
        this.photoShow = top < window.innerHeight + 10;
      }
    },
  },
};
</script>

<style scoped lang="scss">
div {
  h4 {
    // display: inline-block;ï
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: start;
    margin: 12px 0 24px 0;
    padding-left: 48px;
    position: relative;
    z-index: -1;
    // background-color: #b2d5e2;
    // background-position: left top;
  }
  h4::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 30px;
    height: 90%;
    width: 180px;
    background-color: #b2d5e2;
    z-index: -2;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7em;
    text-align: justify;
    margin: 12px 0 18px 0;
    padding: 0 30px 0 60px;
  }
  .css-test {
    white-space: pre-wrap;
  }
  .img-wrap {
    padding: 0 0 0 54px;
    margin-bottom: 64px;

    .car-img {
      width: 18%;
      margin-left: auto;
      margin-right: 24px;
    }
    .photo-img {
      border-radius: 2px;
    }
    .photo-bg {
      display: inline-block;
      position: relative;
      // background-color: #f0f0f0;
    }
    .photo-bg::after {
      content: "";
      position: absolute;
      top: -12px;
      left: -12px;
      height: 100%;
      width: 100%;
      background-color: #e0dfdf;
      border-radius: 2px;
      z-index: -1;
    }
  }
}

.animeTitle-enter-active {
  transition: opacity 2s, transform 2s;
}
.animeTitle-enter {
  opacity: 0;
  transform: translateX(-40px);
}
.animeTitle2-enter-active {
  transition: opacity 2s, transform 2s;
}
.animeTitle2-enter {
  opacity: 0;
  transform: translateX(-80px);
}
@media (min-width: 1024px) {
  div {
    h4 {
      font-size: 16px;
      padding-left: 30px;
      letter-spacing: -0.04em;
    }
    h4::after {
      left: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.7em;
      letter-spacing: -0.01em;
      text-align: justify;
      margin: 12px 0 18px 0;
      padding: 0 30px 0 60px;
    }
    .img-wrap {
      padding: 0 0 0 54px;
      margin-bottom: 12px;
      .car-img {
        width: 18%;
        margin-left: auto;
        margin-right: 24px;
      }
      .photo-bg::after {
        content: "";
        position: absolute;
        top: -12px;
        left: -12px;
        height: 100%;
        width: 100%;
        background-color: #e0dfdf;
        border-radius: 2px;
        z-index: -1;
      }
    }
  }
}
</style>
