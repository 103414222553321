<template>
  <div class="container">
    <section class="lg:grid grid-cols-3 lg:mx-5 lg:mb-10">
      <MaintenanceService
        v-for="(content, index) in contents"
        :key="index"
        :title="content.title"
        :discription="content.discription"
        :linkTitle="content.linkTitle"
        :linkTo="content.linkTo"
        :carImg="{
          alt: content.carImg.alt,
          src: require('@/assets/img/contents/' + content.carImg.src),
        }"
        :photoImg="{
          alt: content.photoImg.alt,
          src: require('@/assets/img/contents/' + content.photoImg.src),
        }"
      >
      </MaintenanceService>
    </section>
  </div>
</template>

<script>
import MaintenanceService from "./MaintenanceService.vue";
import contents from "../assets/json/maitenanceService.json";
export default {
  components: { MaintenanceService },
  data: function () {
    return {
      contents: contents,
    };
  },
  // created: function () {
  //   contents.forEach((content) => {
  //     console.log(content);
  //   });
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .container {
// position: relative;
// padding-bottom: 1px;
// }
// .container::before {
//   display: block;
//   position: absolute;
//   width: 100%;
//   height: 572vw;
//   content: "";
//   background-image: url(../assets/img/top/back-bace.png);
//   background-size: contain;
//   background-repeat: repeat;
//   z-index: -2;
//   opacity: 0.7;
// }
@media (min-width: 768px) {
  .content {
    position: relative;
  }
  .container::before {
    // display: block;
    // position: absolute;
    width: 1000px;
    height: 100%;
    // content: "";
    background-image: none;
    // background-size: cover;
    // background-repeat: repeat;
    // z-index: -2;
    // opacity: 0.7;
  }
}
</style>
