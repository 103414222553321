<template>
  <div class="text-anime">
    <!-- <h1> -->
    <div>
      <span v-for="(t, index) in text1" :key="index" class="item1" v-text="t" />
    </div>
    <div>
      <span v-for="(t, index) in text2" :key="index" class="item1" v-text="t" />
    </div>
    <div>
      <span v-for="(t, index) in text3" :key="index" class="item1" v-text="t" />
    </div>
    <!-- <div>
      <span
        v-for="(t, index) in text1"
        :key="index"
        class="item1"
        :style="{ animationDelay: index * 250 + 'ms' }"
        v-text="t"
      />
    </div> -->
    <!-- <div>
      <span
        v-for="(t, index) in text2"
        :key="index"
        class="item1"
        :style="{ animationDelay: index * 250 + 'ms' }"
        v-text="t"
      />
    </div>
    <div>
      <span
        v-for="(t, index) in text3"
        :key="index"
        class="item1"
        :style="{ animationDelay: index * 250 + 'ms' }"
        v-text="t"
      />
    </div> -->
    <!-- </h1> -->
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      text1: "これまでも、これからも、",
      text2: null,
      text3: null,
      // text2: "車好きの明るいスタッフが",
      // text3: "丁寧に仕事に励みます。",
    };
  },
  methods: {
    dirayAnime1: function () {
      setTimeout(() => {
        this.text2 = "車好きの明るいスタッフが";
      }, 1500);
    },
    dirayAnime2: function () {
      setTimeout(() => {
        this.text3 = "丁寧に仕事に励みます。";
      }, 2800);
    },
  },
  mounted() {
    this.dirayAnime1();
    this.dirayAnime2();
  },
};
</script>

<style scoped lang="scss">
.text-anime {
  margin: auto;
  width: 185px;
  // background-color: aliceblue;
  // writing-mode: vertical-rl;
  //   h1 {
  //     font-weight: 600;
  //     letter-spacing: 3px;
  //     text-orientation: upright;
  //     text-align: start;
  //     position: absolute;
  //     top: -5px;
  //     right: 30%;
  //   }
}
@keyframes text-in1 {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
}
.item1 {
  padding: 0 12px;
  font-size: 18px;
  display: inline-block;
  margin: 0;
  // min-width: 0.3em;
  // font-size: 2rem;
  animation: text-in1 1s cubic-bezier(0.22, 0.15, 0.25, 1.43) 0s backwards;
}

@keyframes text-in2 {
  0% {
    transform: translate(0, -10px);
    opacity: 0;
  }
}
@media (min-width: 1024px) {
  .text-anime {
    width: 100%;
    margin: 36px 0 0 0;
    .item1 {
      // font-family: "Paytone One";
      font-family: "Noto Sans JP", sans-serif;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -2px;
      // color: #f1ebe5;
      color: #202020;
      text-shadow: -1.5px 8.36px 18.896px #ffffff, 1.66px -8px 18px #fff,
        0 5.36px 8.896px #ffffff, 0px -3px 5px #fff, 0 2.36px 8.896px #ffffff;
      margin: 6px 0 17px 0;
    }
  }
}
</style>
